<template>
  <div>
    <b-card :title="pollResult.question">
      <b-list-group>
        <b-list-group-item
          v-for="option in pollResult.options"
          :key="option.id"
        >
          <div class="font-weight-bold font-medium-3">
            {{ option.title }}
          </div>
          <div>
            <b-progress
              max="100"
              variant="primary"
              class="progress-bar-primary"
            >
              <b-progress-bar :value="option.percent">
                <strong>% {{ option.percent }}</strong>
              </b-progress-bar>
            </b-progress>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card
      v-if="pollResult.detail"
      title="Görüşler"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(item,key) in pollResult.detail"
          :key="key"
        >
          <div class="font-weight-bold font-medium-2">
            {{ item.selected }} <small class="text-primary">(Kullanıcı Oyu)</small>
          </div>
          <div>{{ item.comment }}</div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BProgress, BProgressBar,
} from 'bootstrap-vue'

export default {
  name: 'Result',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BProgress,
    BProgressBar,
  },
  computed: {
    pollResult() {
      return this.$store.getters['polls/getPollResult']
    },
  },
  created() {
    this.getPollResult()
  },
  methods: {
    getPollResult() {
      this.$store.dispatch('polls/pollResult', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
